import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SobreNos from "../../components/sobre-nos"
import BackgroundImage from "gatsby-background-image"
import { graphql, Link } from "gatsby"

const ReparoDeVentilador = ({ data }) => {
  return (
    <Layout showHero={true}>
      <SEO title="Conserto De Ventiladores | Cedilar" />
      <SobreNos />
      <div className="container mx-auto justify-between">
        <h1 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Reparo de Ventilador na Grande Porto Alegre
        </h1>
        <p className="px-4 md:px-0 pb-6">
          Com o calor do verão em Porto Alegre é bem possível que normalmente
          não se consiga lidar com tamanha temperatura, precisando assim
          refrescar o calor sentido com um bom ventilador, de qualidade e que
          funcione corretamente, garantindo assim um clima um pouco mais
          confortável.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Manter seu ventilador funcionando bem e com qualidade pode ser um
          desafio se você não conhece uma loja especializada no assunto e que
          possa lhe garantir assistência técnica de qualidade, com possibilidade
          de reposição de peças e trabalho autorizado como a CeDilar. Quer saber
          mais sobre? Então, vamos lá!
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Mantendo a qualidade do seu ventilador
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Para que a qualidade do seu ventilador seja mantida, é sempre bom
          manter ele em condições de limpeza adequada, procurando não utilizar
          substâncias que possam prejudicar as peças superficiais do produto, as
          quais você tem autonomia em limpeza.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Peças internas não devem ser limpas manualmente por você, e sim apenas
          pela assistência especializada no assunto, onde nós da CeDilar unimos
          o conhecimento útil e a qualidade de nossos serviços para oferecer o
          melhor resultado, garantindo assim o máximo de vida útil do seu
          produto.
        </p>
        <p className="px-4 md:px-0 pb-12">
          Quanto ao processo feito de forma caseira em higienização, o ideal é
          que ele ocorra em períodos de 15 dias, dando um carinho especial para
          a limpeza da hélice, onde a poeira mais fica concentrada, e seguindo
          para a{" "}
          <Link to="/" className="text-blue-600">
            limpeza das demais peças do seu ventilador
          </Link>
          , mas sempre as externas, sem mexer nas partes internas para evitar
          danos.
        </p>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="container mx-auto justify-between">
        <h2 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Assistência técnica de qualidade
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Com a assistência técnica CeDilar você tem a oportunidade de contar
          com uma empresa de qualidade, que atua a vários anos no mercado,
          contando com prestação de serviços excepcionais desde a sua
          inauguração, em 15 de maio de 1990, até os dias atuais.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Com a assistência técnica CeDilar você pode ter um serviço à
          domicílio, com atendimento rápido, experiência profissional e ainda
          garantia de 3 meses para peças utilizadas no seu produto, uma vez que
          todas são originais e de fábrica.
        </p>
        <p className="px-4 md:px-0 pb-6">
          A assistência técnica CeDilar trabalha de forma autorizada, servindo
          com excelência para as principais marcas do mercado em
          eletrodomésticos, como Mondial, Cardal, Sintex, Lorenzetti, Hydra
          Corona, Philips Walita, entre outros. Sendo assim, você pode ter a
          segurança de uma empresa que atua com o melhor serviço específico para
          a marca do seu produto, mantendo a garantia que ele já traz de
          fábrica.
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Venda de ventiladores usados
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Na assistência técnica CeDilar você tem ainda a oportunidade de compra
          de produtos. Caso você esteja sem ventilador no momento por qualquer
          que seja o motivo, pode entrar em contato com a CeDilar e fazer a
          compra de um ventilador usado, ainda de qualidade e por um preço que
          cabe no seu bolso.
        </p>

        <p className="px-4 md:px-0 pb-12">
          Para conferir mais a respeito e saber tudo o que temos a oferecer em
          assistência e venda de ventiladores usados, basta ligar ou ir até
          nosso ponto físico de atendimento, onde atendemos pelo telefone (51)
          3217-9658, estando localizados na Avenida Ipiranga, 1477 – Azenha,
          Porto Alegre, RS. Não perca tempo e garanta a melhor assistência do
          mercado para seu eletrodoméstico!
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default ReparoDeVentilador
